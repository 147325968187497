import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';

import TriviaView from '../views/TriviaView.vue';
import TriviaIlumaView from '../views/TriviaIlumaView.vue';
import LeviaView from '../views/LeviaView.vue';
import RandomizerView from '../views/RandomizerView.vue';
import TruthsLiesView from "../views/TruthsLiesView.vue";
import IlluminateView from "../views/IlluminateView.vue";

import StatsView from "../views/StatsView.vue";
import LoginView from "../views/LoginView.vue";
import ls from "localstorage-slim";
import {recordEvent} from "@/ts/helpers";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/trivia',
      name: 'trivia',
      component: TriviaView,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/levia',
      name: 'levia',
      component: LeviaView,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/iluma-iprime',
      name: 'iluma-iprime',
      component: TriviaIlumaView,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/randomizer',
      name: 'randomizer',
      component: RandomizerView,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/truths-lies',
      name: 'truths-lies',
      component: TruthsLiesView,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/color-quiz',
      name: 'color-quiz',
      component: IlluminateView,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/stats',
      name: 'stats',
      component: StatsView,
      meta: {
        requiresAuth: true,
        requiresAdmin: true
      }
    },
    {
      path: '/login',
      name: 'login',
      component: LoginView
    },
    {
      path: '/logout',
      name: 'logout',
      component: LoginView
    }
  ]
});

router.beforeEach(async (to, from, next) => {

  // const loggedIn = await auth.loggedIn();
  //
  // const checkAccessToApp = record => record.meta.appId && !auth.hasAccessToApp(record.meta.appId);
  // const checkPermissionsToView = record => record.meta.permissionId && !auth.hasPermission(record.meta.permissionId );
  const userData = ls.get('user', { decrypt: true });
  const loggedIn = !!userData;

  const isAdmin = userData?.data?.indexOf('Admin:true') > -1;

  if (to.name === "login" && loggedIn) {
    next({ name: "home" });
  }

  if (to.name === "logout") {
    recordEvent('logout');
    ls.remove('user');
    next({ name: "login" });
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!loggedIn) {
      next({
        name: "login",
        // query: { redirect: to.fullPath }
      });
    } else if (to.matched.some(record => record.meta.requiresAdmin) && !isAdmin ) {
      next({
        name: "home",
        // query: { redirect: to.fullPath }
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router
