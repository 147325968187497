<template>
  <main>
    <iframe src="/illuminate/background.html" class="background"/>
    <transition mode="out-in" name="main">
      <div
          class="overlay"
          :class="{
            'warm': result===0,
            'balanced': result===1,
            'cool': result===2
          }"
          v-if="state===2"
      />
    </transition>
    <div class="logo-area">
      <img src="@/assets/icons/logo-10-years.png" class="logo"/>
    </div>
    <RouterLink to="/" class="goToHome"><img src="@/assets/icons/goToHome.svg"></RouterLink>
    <transition mode="out-in" name="main">
      <a class="reset" @click.prevent="reset" v-if="state===1"><img src="@/assets/icons/reset.svg"></a>
    </transition>
    <div class="main-container">
      <Illuminate/>
    </div>

  </main>
</template>

<script setup lang="ts">

import Illuminate from "@/components/Illuminate/Illuminate.vue";
import {onMounted, provide, ref} from "vue";
import {knuthShuffle} from "knuth-shuffle";
import {recordEvent} from "@/ts/helpers";

const questions = ref([
  {
    questionId: 0,
    question: "Ποιο από τα παρακάτω χρώματα σε βάζει σε γιορτινή διάθεση;",
    answer: -1
  },
  {
    questionId: 1,
    question: "Ποιο χρώμα θα επέλεγες για το γιορτινό σου outfit (ντύσιμο), ώστε να νιώθεις άνεση και αυτοπεποίθηση;",
    answer: -1
  },
  {
    questionId: 2,
    question: "Τι χρώμα έχει η πιο όμορφη χριστουγεννιάτικη ανάμνησή σου;",
    answer: -1
  },
]);


const state = ref(0);
const result = ref(-1);

const reset = () => {
  result.value = -1;
  state.value = 0;

  questions.value.forEach(q => {
    q.answer = -1;
  });
};



const answers = ref([
  {
    icon: "/illuminate/BOLD_WARM%2001_PT1.png",
    index: 0
  },
  {
    icon: "/illuminate/BOLD_WARM%2002_PT1.png",
    index: 1
  },
  {
    icon: "/illuminate/BRIGHT_ELEGANT%2001_PT3.png",
    index: 2
  },
  {
    icon: "/illuminate/BRIGHT_ELEGANT%2002_PT3.png",
    index: 3
  },
  {
    icon: "/illuminate/SOFT_BALANCED%2001_PT2.png",
    index: 4
  },
  {
    icon: "/illuminate/SOFT_BALANCED%2002_PT2.png",
    index: 5
  }
]);

const shuffle = () => {
  answers.value = knuthShuffle(answers.value);
}

provide('illuminate', {
  questions,
  results: ref([
    {
      indexes: [0, 1],
      answers: 0,
      index: 0
    },
    {
      indexes: [4, 5],
      answers: 0,
      index: 1
    },
    {
      indexes: [2, 3],
      answers: 0,
      index: 2
    },
  ]),
  state,
  result,
  reset,
  answers,
  shuffle
});

onMounted( () => {
  recordEvent('game-load', 5)
});
</script>

<style scoped lang="scss">
.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  z-index: -2;
}

main {
  width: 100%;
  height: 100vh;
  height: 100svh;
  overflow: hidden;
}

.logo-area {
  position: fixed;
  top: 20px;
  width: 100%;
  pointer-events: none;
  display: flex;
  justify-content: center;
  z-index: 10;
  left: 0;

  @media screen and (min-height: 780px) {
    top: 30px;
  }
}

.logo {
  height: 40px;
}

.main-container {
  width: 100%;
  //height: 100%;
  display: flex;
  flex-flow: column;
  z-index: 10;
  position: relative;
  justify-content: center;
  align-items: center;
  height: 100vh;
  height: 100svh;

  .spacer {
    height: 25%;
    flex: 1 1 auto;
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    filter: blur(100px);
    border-radius: 100%;
    background: #f00;
    transform: translate3d( 60%, -60%, 0 );
    width: 708px;
    height: 266px;

    @media screen and (min-width: 1024px) {
      width: 1041px;
      height: 392px;
    }
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    filter: blur(100px);
    border-radius: 100%;
    background: #f00;
    transform: translate3d( -50%, 50%, 0 );
    width: 636px;
    height: 239px;

    @media screen and (min-width: 1024px) {
      width: 1041px;
      height: 392px;
    }
  }

  &.warm {
    background: #1F1020CC;

    &::before {
      background: radial-gradient(50% 50% at 50% 50%, rgba(255, 32, 177, 0.40) 0%, rgba(255, 33, 37, 0.40) 83.73%);
    }

    &::after {
      background: radial-gradient(50% 50% at 50% 50%, rgba(255, 32, 32, 0.20) 0%, rgba(196, 25, 202, 0.20) 68.23%, rgba(20, 60, 179, 0.20) 86.73%);
    }
  }

  &.balanced {
    background: #0E0E0BCC;

    &::before {
      background: radial-gradient(50% 50% at 50% 50%, rgba(241, 121, 2, 0.50) 0%, rgba(123, 112, 50, 0.50) 100%);
    }

    &::after {
      background: radial-gradient(50% 50% at 50% 50%, rgba(19, 98, 102, 0.40) 0%, rgba(140, 140, 140, 0.40) 100%);
    }
  }

  &.cool {
    background: #031517CC;

    &::before {
      background: radial-gradient(50% 50% at 50% 50%, rgba(32, 255, 67, 0.50) 0%, rgba(106, 255, 151, 0.50) 68.23%, rgba(0, 51, 220, 0.50) 100%);
    }

    &::after {
      background: radial-gradient(50% 50% at 50% 50%, rgba(32, 255, 67, 0.50) 0%, rgba(0, 228, 253, 0.50) 64.23%, rgba(0, 51, 220, 0.50) 86.73%);
    }
  }
}

.main-enter-active,
.main-leave-active {
  transition: opacity 0.8s ease;
}

.main-enter-from,
.main-leave-to {
  opacity: 0;
}

.goToHome {
  top: 26px;
}

.reset {
  position: fixed;

  top: 26px;
  left: 66px;

  cursor: pointer;

  z-index: 5000;

  @media screen and (min-height: 780px) {
    top: 26px;
    left: 82px;
  }
}
</style>
