<template>
<div class="illuminate">
  <transition mode="out-in" name="main">
    <IlluminateIntro v-if="state===0" @start="state = 1" />
    <IlluminateGame v-else-if="state===1" @next="state = 2" />
    <IlluminateResult v-else-if="state===2" @next="state = 3" />
    <IlluminateThanks v-else />
  </transition>
</div>
</template>

<script setup lang="ts">
import {inject, ref} from "vue";

import IlluminateIntro from "@/components/Illuminate/IlluminateIntro.vue";
import IlluminateGame from "@/components/Illuminate/IlluminateGame.vue";
import IlluminateResult from "@/components/Illuminate/IlluminateResult.vue";
import IlluminateThanks from "@/components/Illuminate/IlluminateThanks.vue";

const { state } = inject( 'illuminate', { state: ref(0) } );
</script>

<style scoped lang="scss">
.main-enter-active,
.main-leave-active {
  transition: opacity 0.8s ease;
}

.main-enter-from,
.main-leave-to {
  opacity: 0;
}

</style>
