<template>
  <div class="thanks">
    <h3>
      Σ' ευχαριστούμε πολύ <br> για τη συμμετοχή σου!
    </h3>
    <p>
      Πάμε να δούμε μαζί τα χρώματα των συσκευών
      και των αξεσουάρ που σου ταιριάζουν
      για να λάμψεις και φέτος τις γιορτές;
    </p>
    <a @click.prevent="reset">
      <span>ΠΑΙΞΕ ΞΑΝΑ</span>
      <img src="@/assets/icons/illuminate/play-again.svg" />
    </a>
  </div>
</template>

<script setup lang="ts">

import {inject} from "vue";
import Button from "@/components/Button.vue";

const { reset } = inject('illuminate', { reset: () => {} });

</script>

<style scoped lang="scss">

.thanks {
  display: flex;
  flex-flow: column;
  gap: 26px;
  align-items: center;
  justify-content: center;
  padding: 40px 24px 0;
}

h3 {
  color: var(--iqos-white, #FFFDFB);
  text-align: center;
  /* text-shadow */
  text-shadow: 0px 0px 18px rgba(0, 0, 0, 0.40);
  /* Η3 */
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 28.8px */
  margin: 0;

  @media screen and (min-width: 1024px) {
    font-size: 24px;
  }
}

p {
  color: var(--iqos-white, #FFFDFB);
  text-align: center;
  /* text-shadow */
  text-shadow: 0px 0px 18px rgba(0, 0, 0, 0.40);
  /* base-text-mob */
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 21.6px */
  max-width: 364px;
  margin: 0;

  @media screen and (min-width: 1024px) {
    font-size: 20px;
  }
}

a {
  display: flex;
  align-items: center;
  gap: 14px;
  color: var(--iqos-white, #FFFDFB);
  /* text-shadow */
  text-shadow: 0px 0px 18px rgba(0, 0, 0, 0.40);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%; /* 32px */
  text-transform: uppercase;
  cursor: pointer;
  padding: 16px 32px;
  margin-top: 10px;
}
</style>
