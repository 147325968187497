<template>
  <div class="result">
    <div class="rich" v-if="result===0">
      <h3>Σου ταιριάζουν <br> τα <span>έντονα</span> και <span>ζεστά</span> χρώματα!</h3>
      <div>
        <p>
          Αυτά τα χρώματα αντικατοπτρίζουν την αποφασιστικότητα και τον αυθορμητισμό σου. <br> <br>
          Επιλέγοντάς τα, εκφράζεις τη δυναμική προσωπικότητά σου και μεταδίδεις τη ζωντάνια και τον ενθουσιασμό που
          διαθέτεις στους γύρω σου.
        </p>
      </div>
      <IlluminateButton :active="btnActive" @click="next">ΣΥΝΕΧΕΙΑ</IlluminateButton>
    </div>
    <div class="balanced" v-else-if="result===1">
      <h3>Σου ταιριάζουν <br> τα <span>απαλά</span> και <span>ισορροπημένα</span> χρώματα!</h3>
      <div>
        <p>
          Είναι η πρώτη σου επιλογή, καθώς αντανακλούν την ηρεμία και τη σταθερότητα που σε χαρακτηρίζουν. Τα προτιμάς γιατί δημιουργούν ένα περιβάλλον που σου επιτρέπει να διατηρείς την ισορροπία στις καθημερινές σου στιγμές.
        </p>
      </div>
      <IlluminateButton :active="btnActive" @click="next">ΣΥΝΕΧΕΙΑ</IlluminateButton>
    </div>
    <div class="cool" v-else-if="result===2">
      <h3>Σου ταιριάζουν <br>τα <span>φωτεινά</span> και <span>εκλεπτυσμένα</span> χρώματα!</h3>
      <div>
        <p>
          Αυτά τα χρώματα αντανακλούν τη δημιουργικότητα και τη θετική ενέργεια που σε χαρακτηρίζουν. Τα προτιμάς γιατί σου δίνουν την ελευθερία να εκφράζεις τη μοναδικότητά σου και να γεμίζεις με φως την καθημερινότητά σου.
        </p>
      </div>
      <IlluminateButton :active="btnActive" @click="next">ΣΥΝΕΧΕΙΑ</IlluminateButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import IlluminateButton from "@/components/Illuminate/IlluminateButton.vue";
import {inject, ref} from "vue";

const { result } = inject( 'illuminate', { result: ref(0) } );

const emit = defineEmits(['next']);

const btnActive = ref( false );

const next = () => {
  btnActive.value = true;

  setTimeout( () => {
    emit('next');
  }, 800 );
}
</script>

<style scoped lang="scss">
.result {
  width: 481px;
  padding: 40px 24px 0;

  & > div {
    display: flex;
    flex-flow: column;
    gap: 26px;
    align-items: center;
  }
}

h3 {
  color: var(--iqos-white, #FFFDFB);
  text-align: center;
  /* text-shadow */
  text-shadow: 0px 0px 18px rgba(0, 0, 0, 0.40);
  /* Η3 */
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 28.8px */
  margin: 0;
}

p {
  color: var(--iqos-white, #FFFDFB);
  text-align: center;
  /* text-shadow */
  text-shadow: 0px 0px 18px rgba(0, 0, 0, 0.40);
  /* base-text */
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 24px */
}

.rich h3 span {
  color: #FB95AD;
}

.balanced h3 span {
  color: #FAD4AD;
}

.cool h3 span {
  color: #86E6C3;
}

button {
  margin-top: 10px;
}
</style>
