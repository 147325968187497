<template>
  <main>
    <div class="header">
      <RouterLink to="/"><img src="@/assets/icons/goToHome.svg"></RouterLink>
      <h1>Stats</h1>
      <select v-model="activeUser">
        <option value="">All Users</option>
        <option v-for="user in usersData" :key="user.id" :value="user.id">{{ user.username }}</option>
      </select>
      Date:
      <VueDatePicker v-model="dateRange" range multi-calendars :enable-time-picker="false" format="dd/MM/yyyy"/>
    </div>
    <div class="container">
      <section>
        <h2>Events:</h2>
        <table>
          <thead>
          <tr>
            <th>Game</th>
            <th>Event Type</th>
            <th>Count</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="event in eventsData" :key="event.EventType + event.GameId">
            <td>{{ gamesData[event.GameId]?.name || "-" }}</td>
            <td>{{ event.EventType }}</td>
            <td class="numval">{{ event.Count }}</td>
          </tr>
          </tbody>
        </table>
      </section>
      <section>
        <h2>Games Data:</h2>
        <div v-for="( game, index ) in gamesData" :key="index" class="game-data">
          <h3>{{ game.name }}</h3>
          <table>
            <tr>
              <th>Total Answers</th>
              <td class="numval">{{ game.totalNonFreeTextAnswers }}</td>
            </tr>
            <tr>
              <th>Correct Answers</th>
              <td class="numval">{{ game.totalCorrectAnswers }}</td>
            </tr>
            <tr>
              <th>Total Answers (With free text)</th>
              <td class="numval">{{ game.totalAnswers }}</td>
            </tr>
          </table>
          <div v-if="freeTextData[ index ].questions.length">
            <h4>Free Text Answers</h4>
            <div v-for="(question, index) in freeTextData[ index ].questions" :key="index">
              <h5>{{ question.question }}</h5>
              <table>
                <tr v-for="(answer, index) in question.freetextAnswers" :key="index">
                  <td>{{ answer.answer }}</td>
                  <td class="numval">{{ answer.count }}</td>
                </tr>
                <tr>
                  <th>Total:</th>
                  <td class="numval">{{ question.totalAnswers }}</td>
                </tr>
              </table>

            </div>
          </div>
          <div v-if="game.isIlluminate">
            <table v-for="q in game.questions" :key="q.questionId" style="margin-top: 20px">
              <tr><th colspan="2">{{ q.question }}</th></tr>
              <tr v-for="ans in game.answers" :key="ans.index">
                <td>
                  <img :src="ans.icon" alt="" width="30" height="30" style="display: block">
                </td>
                <td>
                  {{ getAnswerCount( game.id, q.questionId, ans.index ) }}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </section>
    </div>
  </main>
</template>

<script setup lang="ts">
import {ref, computed, onMounted, watch, nextTick} from 'vue';
import {getUsers, getAnswers, getEvents} from "@/ts/helpers";
import {useHead} from "@unhead/vue";
import moment from "moment";

const allEvents = ref([]);
const allAnswers = ref([]);

const usersData = ref([]);
const eventsData = ref([]);
const answersData = ref([]);

const activeUser = ref("");

const dateRange = ref();


watch( [activeUser, dateRange], async () => {
  await nextTick();

  updateData();
  console.log('update data');
});

const activeEvents = computed( () => {

  let startM, endM;

  if( dateRange.value ) {
    const [start, end] = dateRange.value;

    startM = moment(start).startOf('day');
    endM = moment(end).endOf('day');
  }

  return allEvents
      .value
      .filter( ( e ) => {
        let dateCheck = true;
        let userCheck = true;

        if( dateRange.value ) {
          dateCheck = startM.isSameOrBefore( e.dateCreated ) && endM.isSameOrAfter( e.dateCreated );
        }

        if( activeUser.value !== "" ) {
          userCheck = e.userCreated === activeUser.value
        }

        return dateCheck && userCheck;
      });
});

const activeAnswers = computed( () => {

  let startM, endM;

  if( dateRange.value ) {
    const [start, end] = dateRange.value;

    startM = moment(start).startOf('day');
    endM = moment(end).endOf('day');
  }

  return allAnswers
      .value
      .filter( ( e ) => {
        let dateCheck = true;
        let userCheck = true;

        if( dateRange.value ) {
          dateCheck = startM.isSameOrBefore( e.dateCreated ) && endM.isSameOrAfter( e.dateCreated );
        }

        if( activeUser.value !== "" ) {
          userCheck = e.userCreated === activeUser.value
        }

        return dateCheck && userCheck;
      });
});

onMounted(() => {
  load();
});

const updateData = () => {
  eventsData.value = mergeEvents(activeEvents.value );
  answersData.value = fixAnswers(activeAnswers.value );
  findAnswers();
};

const load = async () => {
  try {
    const [users, events, answers] = await Promise.all([
      getUsers(),
      getEvents(),
      getAnswers()
    ]);

    usersData.value = users;

    console.log( events );

    allEvents.value = events;
    allAnswers.value = answers;

    updateData();

  } catch (e) {
    console.error(e);
  }
}

const mergeEvents = (events) => {

  events = events.map(event => {

    const data = {};

    const dataArray = event.data.slice(1, event.data.length - 1).split(', ').map((e) => {
      const [key, value] = e.split(':');
      data[key] = value;
    });

    return {
      ...event,
      ...data
    }
  });

  const merged = [];

  events.forEach(event => {
    const e = merged.find(e => e.EventType === event.EventType && e.GameId === event.GameId);

    if (e) {
      e.Count++;
    } else {
      merged.push({
        ...event,
        Count: 1
      });
    }
  });

  return merged;

}

const gamesData = ref([
  {
    id: 0,
    name: "2 Αλήθειες & 1 Ψέμα",
    totalAnswers: 0,
    totalNonFreeTextAnswers: 0,
    totalCorrectAnswers: 0,
    questions: [
      {
        totalAnswers: 0,
        correctAnswers: 0,
        correctAnswer: 0,
        questionId: 0,
      },
      {
        totalAnswers: 0,
        correctAnswers: 0,
        correctAnswer: 0,
        questionId: 1,
      },
      {
        totalAnswers: 0,
        correctAnswers: 0,
        correctAnswer: 0,
        questionId: 2,
      },
      {
        totalAnswers: 0,
        correctAnswers: 0,
        correctAnswer: 0,
        questionId: 3,
      },
      {
        totalAnswers: 0,
        correctAnswers: 0,
        correctAnswer: 0,
        questionId: 4,
      },
    ]
  },
  {
    id: 1,
    name: "Digital Trivia",
    totalAnswers: 0,
    totalNonFreeTextAnswers: 0,
    totalCorrectAnswers: 0,
    questions: [
      {
        question: "Έχει κυκλοφορήσει συσκευή IQOS σχεδιασμένη από διάσημο σχεδιαστή μόδας;",
        answers: [
          "Ναι",
          "Όχι"
        ],
        totalAnswers: 0,
        correctAnswers: 0,
        correctAnswer: 0,
        questionId: 0,
      },
      {
        question: "Ποιος σχεδιαστής θα θέλατε να σχεδιάσει την επόμενη συσκευή IQOS;",
        totalAnswers: 0,
        freetextAnswers: [],
        freetext: true,
        questionId: 1,
      },
      {
        question: "Πως λέγονται οι ράβδοι καπνού που μπορείς να χρησιμοποιήσεις με το IQOS ILUMA;",
        answers: [
          "TEREA",
          "HEETS",
          "FITS",
        ],
        totalAnswers: 0,
        correctAnswers: 0,
        correctAnswer: 0,
        questionId: 2,
      },
      {
        question: "Τι γεύση έχει η απόλαυση για σένα;",
        totalAnswers: 0,
        freetextAnswers: [],
        freetext: true,
        questionId: 3,
      },
      {
        question: "Πόσοι Έλληνες καταναλωτές επιλέγουν IQOS;",
        answers: [
          "Περισσότεροι από 500.000",
          "300.000",
          "100.000"
        ],
        totalAnswers: 0,
        correctAnswers: 0,
        correctAnswer: 0,
        questionId: 4,
      },
      {
        question: "Πόσα χρόνια πραγματοποιείται το festival «Salt Mountain»;",
        answers: [
          "3",
          "10",
          "6"
        ],
        totalAnswers: 0,
        correctAnswers: 0,
        correctAnswer: 2,
        questionId: 5,
      },
      {
        question: "Ποιο συγκρότημα έχει πουλήσει τους περισσότερους δίσκους παγκοσμίως μέχρι και σήμερα;",
        answers: [
          "The Beatles",
          "Aerosmith",
          "Police"
        ],
        totalAnswers: 0,
        correctAnswers: 0,
        correctAnswer: 0,
        questionId: 8,
      },
      {
        question: "Σε ποια μουσική συναυλία θα ήθελες να συναντήσεις το IQOS;",
        totalAnswers: 0,
        freetextAnswers: [],
        freetext: true,
        questionId: 6,
      },
      {
        question: "Ποιο είναι το αγαπημένο σου τραγούδι από τους Coldplay;",
        totalAnswers: 0,
        freetextAnswers: [],
        freetext: true,
        questionId: 10,
      },
      {
        question: "Τι σημαίνει pop μουσική;",
        answers: [
          "Δημοφιλής",
          "Λαϊκή",
          "Έντεχνη",
        ],
        totalAnswers: 0,
        correctAnswers: 0,
        correctAnswer: 0,
        questionId: 7,
      },
      {
        question: "Τι είναι το contouring;",
        answers: [
          "Είδος γυμναστικής",
          "Τεχνική μανικιούρ",
          "Τεχνική μακιγιάζ",
        ],
        totalAnswers: 0,
        correctAnswers: 0,
        correctAnswer: 2,
        questionId: 9,
      },
      {
        question: "Ποια ήταν η τελευταία συλλεκτική συσκευή IQOS;",
        answers: [
          "OASIS",
          "NEON",
          "STARDRIFT"
        ],
        totalAnswers: 0,
        correctAnswers: 0,
        correctAnswer: 1,
        activeAnswer: -1,
        questionId: 11,
      },
      {
        question: "Σε πόσα μεγάλα φεστιβάλ μουσικής είχε παρουσία αυτό το καλοκαίρι το IQOS;",
        answers: [
          "3",
          "1",
          "6",
        ],
        totalAnswers: 0,
        correctAnswers: 0,
        correctAnswer: 2,
        activeAnswer: -1,
        questionId: 12,
      },
      {
        question: "Σε ποιο μέρος του κόσμου σε ταξιδεύει φέτος το IQOS για να ζήσεις, μέσα σε όλα, ένα ανεπανάληπτο μουσικό event;",
        answers: [
          "Παρίσι",
          "Νέα Υόρκη",
          "Τόκιο",
        ],
        totalAnswers: 0,
        correctAnswers: 0,
        correctAnswer: 2,
        activeAnswer: -1,
        questionId: 13,
      },
      {
        question: "Πως ονομάζονται οι δύο τελευταίες, σε κυκλοφορία, γεύσεις TEREA;",
        answers: [
          "Kona & Dore",
          "Island Beat & Electro Rouge",
          "Bronze & Amber",
        ],
        totalAnswers: 0,
        correctAnswers: 0,
        correctAnswer: 0,
        activeAnswer: -1,
        questionId: 14,
      },
      {
        question: "Πως λέγονται οι ράβδοι που δεν περιέχουν φύλλα καπνού;",
        answers: [
          "TEREA",
          "LEVIA",
        ],
        totalAnswers: 0,
        correctAnswers: 0,
        correctAnswer: 1,
        activeAnswer: -1,
        questionId: 15,
      },
      {
        question: "Πόσα χρόνια κλείνει φέτος το IQOS στην παγκόσμια αγορά;",
        answers: [
          "5",
          "10",
          "20",
        ],
        totalAnswers: 0,
        correctAnswers: 0,
        correctAnswer: 1,
        activeAnswer: -1,
        questionId: 16,
      },
      {
        question: "Πότε κυκλοφόρησε το IQOS στην Ελλάδα;",
        answers: [
          "2016",
          "2020",
          "2022",
        ],
        totalAnswers: 0,
        correctAnswers: 0,
        correctAnswer: 0,
        activeAnswer: -1,
        questionId: 17,
      },
    ]
  },
  {
    id: 2,
    name: "Digital Trivia Levia",
    totalAnswers: 0,
    totalNonFreeTextAnswers: 0,
    totalCorrectAnswers: 0,
    questions: [
      {
        question: "Ποια εποχή βγαίνουν τα μούρα;",
        answers: [
          "Χειμώνα",
          "Άνοιξη",
          "Φθινόπωρο"
        ],
        correctAnswer: 2,
        correctAnswers: 0,
        activeAnswer: -1,
        questionId: 0,
      },
      {
        question: "Τι είδος φυτού είναι η μέντα;",
        answers: [
          "Δέντρο",
          "Αρωματικό Φυτό"
        ],
        correctAnswer: 1,
        correctAnswers: 0,
        activeAnswer: -1,
        questionId: 1,
      },
      {
        question: "Ανήκουν τα blueberries στα φρούτα του δάσους;",
        answers: [
          "Ναι",
          "Όχι"
        ],
        correctAnswer: 0,
        correctAnswers: 0,
        activeAnswer: -1,
        questionId: 2,
      },
      {
        question: "Ποιο αρωματικό φυτό έχει συνδεθεί με τη δροσιά;",
        answers: [
          "Μέντα",
          "Δενδρολίβανο",
          "Ρίγανη"
        ],
        correctAnswer: 0,
        correctAnswers: 0,
        activeAnswer: -1,
        questionId: 3,
      },
    ]
  },
  {
    id: 3,
    name: "Randomizer",
    totalAnswers: 0,
    totalNonFreeTextAnswers: 0,
    totalCorrectAnswers: 0,
    questions: []
  },
  {
    id: 4,
    name: "Digital Trivia Iluma",
    totalAnswers: 0,
    totalNonFreeTextAnswers: 0,
    totalCorrectAnswers: 0,
    questions: [
      {
        question: "Ποιο στοιχείο της Οθόνης Αφής υποδεικνύει την κατάσταση προθέρμανσης του IQOS ILUMA i PRIME;",
        answers: [
          "Ο φωτεινός δακτύλιος",
          "Οι κάθετες γραμμές",
          "Το χρώμα της οθόνης"
        ],
        totalAnswers: 0,
        correctAnswers: 0,
        correctAnswer: 0,
        activeAnswer: -1,
        questionId: 0,
      },
      {
        question: "Στην Οθόνη Αφής του IQOS ILUMA i PRIME, μπορείς να δεις:",
        answers: [
          "Πόσες εμπειρίες σου έχουν απομείνει",
          "Τον υπολειπόμενο χρόνο εμπειρίας σου",
          "Όλα τα παραπάνω"
        ],
        totalAnswers: 0,
        correctAnswers: 0,
        correctAnswer: 2,
        activeAnswer: -1,
        questionId: 1,
      },
      {
        question: "Για να ενεργοποιήσεις τη Λειτουργία Παύσης του IQOS ILUMA i PRIME, ποια κίνηση πρέπει να κάνεις στην Οθόνη Αφής;",
        answers: [
          "Να σύρεις προς τα αριστερά",
          "Να σύρεις προς τα κάτω",
          "Να σύρεις προς τα δεξιά"
        ],
        totalAnswers: 0,
        correctAnswers: 0,
        correctAnswer: 1,
        activeAnswer: -1,
        questionId: 2,
      },
      {
        question: "Όταν ενεργοποιείς τη Λειτουργία Παύσης του IQOS ILUMA i PRIME, μπορείς να κάνεις διάλειμμα από την εμπειρία σου...",
        answers: [
          "για 30 δευτερόλεπτα",
          "μέχρι 3 λεπτά",
          "μέχρι 8 λεπτά"
        ],
        totalAnswers: 0,
        correctAnswers: 0,
        correctAnswer: 2,
        activeAnswer: -1,
        questionId: 3,
      },
      {
        question: "Με το Flex Puff του IQOS ILUMA i PRIME, πόσες επιπλέον εισπνοές μπορείς να απολαύσεις;",
        answers: [
          "Έως 4",
          "Έως 14",
          "Έως 24"
        ],
        totalAnswers: 0,
        correctAnswers: 0,
        correctAnswer: 0,
        activeAnswer: -1,
        questionId: 4,
      },
      {
        question: "Με το Flex Battery του IQOS ILUMA i PRIME, μέχρι πόσες συνεχόμενες χρήσεις μπορείς να κάνεις;",
        answers: [
          "Έως 3",
          "Έως 13",
          "Έως 23"
        ],
        totalAnswers: 0,
        correctAnswers: 0,
        correctAnswer: 0,
        activeAnswer: -1,
        questionId: 5,
      },
    ]
  },
  {
    id: 5,
    name: "Illuminate",
    totalAnswers: 0,
    totalNonFreeTextAnswers: 0,
    totalCorrectAnswers: 0,
    isIlluminate: true,
    questions: [
      {
        questionId: 0,
        question: "Ποιο από τα παρακάτω χρώματα σε βάζει σε γιορτινή διάθεση;",
        totalAnswers: 0,
        correctAnswers: 0,
        correctAnswer: 2,
      },
      {
        questionId: 1,
        question: "Ποιο χρώμα θα επέλεγες για το γιορτινό σου outfit (ντύσιμο), ώστε να νιώθεις άνεση και αυτοπεποίθηση;",
        totalAnswers: 0,
        correctAnswers: 0,
        correctAnswer: 2,
      },
      {
        questionId: 2,
        question: "Τι χρώμα έχει η πιο όμορφη χριστουγεννιάτικη ανάμνησή σου;",
        totalAnswers: 0,
        correctAnswers: 0,
        correctAnswer: 2,
      },
    ],
    answers: [
      {
        icon: "/illuminate/BOLD_WARM%2001_PT1.png",
        index: 0
      },
      {
        icon: "/illuminate/BOLD_WARM%2002_PT1.png",
        index: 1
      },
      {
        icon: "/illuminate/BRIGHT_ELEGANT%2001_PT3.png",
        index: 2
      },
      {
        icon: "/illuminate/BRIGHT_ELEGANT%2002_PT3.png",
        index: 3
      },
      {
        icon: "/illuminate/SOFT_BALANCED%2001_PT2.png",
        index: 4
      },
      {
        icon: "/illuminate/SOFT_BALANCED%2002_PT2.png",
        index: 5
      }
    ]
  },
]);

const freeTextData = computed(() => {
  return gamesData.value.map(game => {
    return {
      ...game,
      questions: game.questions.filter(q => q.freetext)
    };
  });
})

const fixAnswers = (answers) => {
  answers = answers.map(answer => {

    const data = {};

    const dataArray = answer.data.slice(1, answer.data.length - 1).split(', ').map((e) => {
      const [key, value] = e.split(':');
      data[key] = value;
    });

    return {
      ...answer,
      ...data,
      GameId: parseInt(data.GameId),
      QuestionId: parseInt(data.QuestionId),
      Correct: data.Correct === "true"
    }
  });

  return answers;
}

const findAnswers = () => {
  gamesData.value.forEach((game) => {
    //Reset Stats
    game.totalAnswers = 0;
    game.totalCorrectAnswers = 0;
    game.totalNonFreeTextAnswers = 0;

    game.questions.forEach((question) => {

      const answers = answersData.value.filter(a => a.GameId === game.id && a.QuestionId === question.questionId);

      question.totalAnswers = answers.length;
      game.totalAnswers += question.totalAnswers;

      if (question.freetext) {

        question.freetextAnswers.length = 0;

        answers.forEach(a => {

          a.Answer = (a.Answer || "").trim();

          if (!a.Answer) {
            question.totalAnswers -= 1;
            game.totalAnswers -= 1;
            return;
          }

          const ans = question.freetextAnswers.find(fa => fa.answer === a.Answer);

          if (ans) {
            ans.count++;
          } else {
            question.freetextAnswers.push({answer: a.Answer, count: 1});
          }

          // question.freetextAnswers.push(a.Answer);
        });

      } else {
        game.totalNonFreeTextAnswers += question.totalAnswers;
        question.correctAnswers = answers.filter(a => a.Correct).length;
        game.totalCorrectAnswers += question.correctAnswers;
      }
    });
  });
}

const getAnswerCount = (gameId, questionId, answer) => {
  return answersData.value.filter(a => {
    return a.GameId === gameId && a.QuestionId === questionId && parseInt(a.Answer) === answer
  }).length;
}

useHead({
  title: 'Stats - Activation Arena'
});

</script>

<style lang="scss" scoped>

main {
  padding: 15px;
}

table {
  border-collapse: collapse;
}

td, th {
  text-align: left;
  padding: 5px 10px;
  border: 1px solid #fff;

  &.numval {
    text-align: center;
    min-width: 80px;
  }
}

.game-data {
  & + .game-data {
    margin-top: 50px;
  }
}

.container {

  section + section {
    margin-top: 80px;
  }

  @media screen and (min-width: 1024px) {
    display: flex;
    gap: 150px;

    section + section {
      margin-top: 0;
    }

  }
}

.header {
  display: flex;
  align-items: center;
  gap: 15px;

  h1 {
    margin: 0;
  }
  img {
    display: block;
  }
}

.dp__main {
  width: 350px;
}

</style>
