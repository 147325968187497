<template>
  <div class="intro">
    <div>
      <h1>ΝιΩσε τη λΑμψη</h1>
      <h2>ΤΩΝ ΓΙΟΡΤΩΝ</h2>
    </div>
    <p>Ανακάλυψε τα χρώματα που σου ταιριάζουν και θα σε κάνουν να λάμψεις φέτος τις γιορτές!</p>
    <IlluminateButton :active="btnActive" @click="start">ΚΑνε το Quiz</IlluminateButton>
  </div>
</template>

<script setup lang="ts">
import IlluminateButton from "@/components/Illuminate/IlluminateButton.vue"
import {ref} from "vue";
import {recordEvent} from "@/ts/helpers";

const emit = defineEmits(['start']);

const btnActive = ref(false);
const start = () => {
  btnActive.value = true;

  setTimeout( () => {
    recordEvent('quiz-start', 5);

    emit('start');
  }, 800 );
}
</script>

<style scoped lang="scss">
.intro {
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 26px;
  padding: 40px 12px 0;
}

h1 {
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 90%; /* 36px */
  letter-spacing: -2px;
  text-transform: uppercase;

  color: var(--iqos-white, #FFFDFB);
  text-align: center;
  /* text-shadow */
  text-shadow: 0px 0px 18px rgba(0, 0, 0, 0.40);
  margin: 0 0 4px;



  @media screen and (min-width: 1024px) {
    font-size: 68px;
    font-style: normal;
    font-weight: 700;
    line-height: 90%; /* 61.2px */
    letter-spacing: -3.4px;
    text-transform: uppercase;
  }
}

h2 {
  color: var(--iqos-white, #FFFDFB);
  text-align: center;
  /* text-shadow */
  text-shadow: 0px 0px 18px rgba(0, 0, 0, 0.40);
  /* H2-mob */
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 90%; /* 25.2px */
  letter-spacing: -0.84px;
  text-transform: uppercase;
  margin: 0;
  @media screen and (min-width: 1024px) {
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 90%; /* 32.4px */
    letter-spacing: -1.08px;
    text-transform: uppercase;
  }
}

p {
  color: var(--iqos-white, #FFFDFB);
  text-align: center;
  /* text-shadow */
  text-shadow: 0px 0px 18px rgba(0, 0, 0, 0.40);
  /* base-text-mob */
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 21.6px */
  max-width: 364px;
  margin: 0;
  @media screen and (min-width: 1024px) {
    color: var(--iqos-white, #FFFDFB);
    text-align: center;
    /* text-shadow */
    text-shadow: 0px 0px 18px rgba(0, 0, 0, 0.40);
    /* base-text */
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 24px */
  }

}
</style>
